<template>
  <div class="bg-white">
    <form @submit.prevent="submitForm" class="p-4 cm-form" v-if="loaded">
      <div class="flex flex-col">
        <div class="flex flex-col md:flex-row mb-4 text-lg -px-2">
          <div>
            <div>
              <span class="ml-1 font-semibold">{{ $t("patient.add_new") }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col md:flex-row">
        <div class="mr-4">
          <div>
            <label class="block text-base">{{ $t("patient.ssn") }}</label>
          </div>
          <div class="relative">
            <cm-input-text
              focus
              :required="true"
              autocomplete="off"
              id="ssn"
              name="ssn"
              v-model="patient.patient.ssn"
              @change="ssnValidated"
            />
          </div>
        </div>

        <div class="mr-4">
          <div>
            <label class="block text-base">{{ $t("patient.gender") }}</label>
          </div>
          <div class="relative">
            <cm-select
              id="gender"
              name="gender"
              v-model="patient.patient.gender"
              :options="genderOptions()"
              :required="true"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col md:flex-row">
        <div class="mr-4">
          <div>
            <label class="block text-base">{{ $t("patient.first_name") }}</label>
          </div>
          <div class="relative">
            <cm-input-text
              type="text"
              autocomplete="off"
              id="first_name"
              name="first_name"
              :required="true"
              :disabled="!validSsn"
              v-model="patient.patient.first_name"
            />
          </div>
        </div>

        <div class="mr-4">
          <div>
            <label class="block text-base">{{ $t("patient.last_name") }}</label>
          </div>
          <div class="relative">
            <cm-input-text
              type="text"
              autocomplete="off"
              id="last_name"
              name="last_name"
              :required="true"
              :disabled="!validSsn"
              v-model="patient.patient.last_name"
            />
          </div>
        </div>
      </div>

      <!-- <div class="mr-4">
        <div>
          <label class="block text-base">{{ $t("patient.inclusion_date") }}</label>
        </div>
        <div class>
          <cm-datepicker2
            id="bas_inclusion_date"
            name="bas_inclusion_date"
            :max-date="maxDate"
            v-model="patient.bas_inclusion_date"
            :meta="{ mandatory: false }"
          />
        </div>
      </div> -->

      <div class="mr-4">
        <div>
          <label class="block text-base">{{ $t("patient.diagnosis.diagnosis") }}</label>
        </div>
        <div>
          <cm-search-select
            :options="selectableDiagnoses"
            name="name"
            valueProp="id"
            placeholder="Välj diagnos"
            @select="patient.patient_diagnoses[0].id = $event"
          />
        </div>
        <!-- <div class="relative">
          <cm-select
            id="diagnosis_id"
            name="diagnosis_id"
            valueProp="id"
            nameProp="name"
            v-model="patient.patient_diagnoses[0].diagnosis_id"
            :options="diagnoses"
            :required="true"
          />
        </div> -->
      </div>

      <div class="flex flex-col md:flex-row">
        <div class="mr-4">
          <div>
            <label class="block text-base">{{ $t("patient.diagnosis.debute_date") }}</label>
          </div>
          <div class>
            <cm-datepicker2
              id="diagnosis_debute"
              name="diagnosis_debute"
              :max-date="maxDate"
              v-model="patient.patient_diagnoses[0].onset_date"
              :title="$t('patient.diagnosis.debute_date')"
              :meta="{
                mandatory: false,
              }"
            />
          </div>
        </div>

        <div class="mr-4">
          <div>
            <label class="block text-base">{{ $t("patient.diagnosis.diagnosis_date") }}</label>
          </div>
          <div>
            <cm-datepicker2
              id="diagnosis_date"
              name="diagnosis_date"
              :min-date="patient.patient_diagnoses[0].onset_date"
              :max-date="maxDate"
              v-model="patient.patient_diagnoses[0].date"
              :title="$t('patient.diagnosis.diagnosis_date')"
              :meta="{ mandatory: false }"
            />
          </div>
        </div>

        <div class="mr-4">
          <div>
            <label class="block text-base">{{ $t("patient.diagnosis.remiss_date") }}</label>
          </div>
          <div>
            <cm-datepicker2
              id="diagnosis_remiss"
              name="diagnosis_remiss"
              :max-date="patient.patient_diagnoses[0].onset_date"
              v-model="patient.patient_diagnoses[0].remiss_date"
              :title="$t('patient.diagnosis.remiss_date')"
              :meta="{ mandatory: false }"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col md:flex-row mt-4">
        <div class="mr-4">
          <div>
            <label class="block text-base">{{ $t("patient.consent.registry") }}</label>
          </div>
          <div class="relative">
            <cm-select
              id="category"
              name="category"
              v-model="patient.patient_consents[0].registry_id"
              :options="selectableRegistries"
              valueProp="id"
              nameProp="name"
              :required="true"
            />
          </div>
        </div>

        <div class="mr-4">
          <div>
            <label class="block text-base">{{ $t("patient.consent.consent") }}</label>
          </div>
          <div class="relative">
            <cm-select
              id="bas_consent"
              name="bas_consent"
              v-model.number="patient.patient_consents[0].status"
              :options="consentOptions()"
            />
          </div>
        </div>

        <div class="mr-4">
          <div>
            <label class="block text-base">{{ $t("patient.consent.consent_date") }}</label>
          </div>
          <div class>
            <cm-datepicker2
              id="consent_date"
              name="consent_date"
              :min-date="patient.patient_diagnoses[0].onset_date"
              :max-date="maxDate"
              v-model="patient.patient_consents[0].consented_at"
              :meta="{ mandatory: false }"
              :disabled="+patient.patient_consents[0].status !== 1"
              :title="$t('patient.consent.consent_date')"
            />
          </div>
        </div>

        <div class="mr-4">
          <div>
            <label class="block text-base">{{ $t("patient.consent.consent_name") }}</label>
          </div>
          <div class>
            <cm-input-text
              autocomplete="off"
              id="consent_name"
              name="consent_name"
              v-model="patient.patient_consents[0].consented_by"
              :disabled="+patient.patient_consents[0].status !== 1"
            />
          </div>
        </div>
      </div>

      <!-- <div class="mr-4">
        <div class="lg:w-2/5">
          <label class="block text-base">{{ $t("patient.patient_comment") }}</label>
        </div>
        <cm-textarea v-model="patient.patient.comment" name="patient_comment" />
      </div> -->

      <div class="flex justify-start py-2 mt-4">
        <cm-button type="submit" class="btn btn-green mr-2">{{ $t("button.save") }}</cm-button>
        <cm-button type="button" class="btn btn-white mr-2 focus:border-2" @click="cancel">
          {{ $t("button.cancel") }}
        </cm-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  SwedishSsnValidator,
  SwedishSamordningValidator,
  SwedishReserveValidator,
} from "@/utils/validators/ssnvalidator.js";

export default {
  name: "PatientCreate",
  data() {
    return {
      validity: {
        swedishSsn: null,
        swedishSamordning: null,
        swedishReserve: null,
      },
      validSsn: null,
      patient: {
        comment: null,
        patient_diagnoses: [{}],
        patient_consents: [
          {
            status: null,
            consented_at: null,
            consented_by: null,
          },
        ],
        patient: {},
      },
      loaded: false,
    };
  },
  created() {
    this.load();
  },
  computed: {
    ...mapState("user", {
      registries: state => state.registries,
    }),
    ...mapState("meta", {
      diagnoses: state => state.diagnoses,
      registryDiagnoses: state => state.registryDiagnoses,
      structure: state => state.formstructure,
    }),
    categories() {
      var categories = [];
      for (var i = 0; i < this.registries.length; i++) {
        for (var n = 0; n < this.registries[i].category.length; n++) {
          categories.push(this.registries[i].category[n]);
        }
      }
      return categories;
    },
    selectableDiagnoses() {
      var categories = [];
      this.registries.map(function (r) {
        r.diagnosis_categories.forEach(c => {
          categories.push(c.id);
        });
      });

      var categoryDiagnoses = [];
      this.registryDiagnoses.map(function (r) {
        if (categories.indexOf(r.category.id) != -1) {
          categoryDiagnoses.push(r.diagnosis);
        }
      });

      return categoryDiagnoses;
    },
    selectableRegistries() {
      return this.registries.map(function (r) {
        return r;
      });
    },
    maxDate() {
      return new Date();
    },
  },
  methods: {
    async submitForm() {
      try {
        const patient = await this.$store.dispatch("patient/create", this.patient); //Create patient

        if (!patient) {
          const existingPatient = await this.$store.dispatch("patient/getPatientBySsn", this.patient.patient.ssn); //Check if new patient is missing because it already exists

          if (existingPatient) {
            let buttons = [
              {
                title: this.$t("patient.dialog.no"),
                handler: () => {
                  this.$modal.hide("dialog");
                  this.$router.push({ name: "user-patients" });
                },
              },
              {
                title: this.$t("patient.dialog.yes"),
                handler: () => {
                  this.$modal.hide("dialog");
                  this.$store.dispatch("user/activatePatient", existingPatient.data[0].id);
                },
              },
            ];

            this.$modal.show("dialog", {
              title: this.$t("patient.dialog.conflict.title"),
              text: this.$t("patient.dialog.conflict.text"),
              buttons: buttons,
            });
          }
        }

        let patientId = patient.data.id;

        // this.patient.diagnoses[0].diagnosis_id = parseInt(this.patient.diagnoses[0].diagnosis_id);

        // this.$store.dispatch("user/setPatient", patientId).then(() => {
        //   //Set patient
        //   this.$store.dispatch("patient/addDiagnosis", this.patient.diagnoses[0]); //Create pd
        // });

        let buttons = [
          {
            title: this.$t("patient.dialog.no"),
            handler: () => {
              this.$modal.hide("dialog");
              this.$router.push({ name: "user-patients" });
            },
          },
          {
            title: this.$t("patient.dialog.yes"),
            handler: () => {
              this.$modal.hide("dialog");
              this.$store.dispatch("user/activatePatient", patientId);
            },
          },
        ];

        this.$modal.show("dialog", {
          title: this.$t("patient.dialog.create.title"),
          text: this.$t("patient.dialog.create.text"),
          buttons: buttons,
        });
        console.log("ok?");
      } catch (e) {
        console.log(e);
        console.log("error.....");
        console.log(e.name); // eslint-disable-line
        console.log(e.details); // eslint-disable-line
        console.log(e.date); // eslint-disable-line
      }
    },
    cancel() {
      this.$router.push({ name: "user-patients" });
    },
    async ssnValidated() {
      this.validSsn = false;
      const regex = /\s|-|\+/gi;

      const value = this.patient.patient.ssn.replace(regex, "");

      this.century = value.length === 12 && ["19", "20"].indexOf(value.substr(0, 2)) >= 0 ? true : false;

      this.validity.swedishSsn = SwedishSsnValidator(this.century ? value.substr(2) : value);
      this.validity.swedishSamordning = SwedishSamordningValidator(value);
      this.validity.swedishReserve = SwedishReserveValidator(value);

      const validityStatus = Object.values(this.validity);
      const anyValid = validityStatus.includes(true);

      const unknown = validityStatus.every(d => d === null);
      let fullSsn = null;
      if (!this.century && anyValid) {
        if (new Date().getFullYear() < parseInt("19" + value.substr(0, 2)) + 101) {
          fullSsn = "19" + value;
        } else {
          fullSsn = "20" + value;
        }
      } else {
        if (anyValid) {
          fullSsn = value;
        }
      }

      let type = null;
      if (unknown !== true) {
        type = this.validity.swedishSsn ? "ssn" : this.validity.swedishSamordning ? "samordning" : "reserve";
      }

      //check with spar and get more info on patient only when it's possible to do so
      if (["ssn", "samordning"].includes(type)) {
        this.patient.birth_date = fullSsn.substr(0, 8);
        let info = [];
        try {
          const response = await this.$store.dispatch("service/spar", value);

          info = response.data;
        } catch (error) {
          console.log(error); // eslint-disable-line
        }

        if (Object.keys(info).length != 0) {
          //To prevent disabling of valid ssns that have no returndata eg 191212121212
          this.validSsn = true; //Disable name inputs if we can get the values from spar
          this.patient.patient.first_name = info.first_name;
          this.patient.patient.last_name = info.last_name;
          this.patient.patient.birth_date = info.birth_date;
          this.patient.patient.gender = info.gender;
        }
      } else {
        this.patient.patient.first_name = null;
        this.patient.patient.last_name = null;
        this.patient.patient.birth_date = null;
        this.patient.patient.gender = null;
        this.validSsn = true;
      }
    },
    genderOptions() {
      return [
        { value: "M", translation: { label: "Man" } },
        { value: "K", translation: { label: "Kvinna" } },
      ];
    },
    consentOptions() {
      return [
        { value: 0, translation: { label: "Ej inhämtat" } },
        { value: 1, translation: { label: "Ja" } },
        { value: 2, translation: { label: "Nej" } },
      ];
    },
    options(varname) {
      if (this.structure) {
        for (var i = 0; i < this.structure.variables.length; i++) {
          if (this.structure.variables[i].varname == varname) {
            return this.structure.variables[i].optionslist;
          }
        }
      }
    },
    async load() {
      await this.$store.dispatch("user/getRegistries");
      await this.$store.dispatch("meta/diagnoses");
      await this.$store.dispatch("meta/registryDiagnoses");
      // await this.$store.dispatch("meta/loadFormStructure", "patient");

      this.loaded = true;
    },
  },
};
</script>
