const SwedishSsnValidator = (value) => {
    if (!value) { return null }

    if (value.indexOf('-') === -1) {
        let splitter = value.length === 10 ? 6 : 8
        value = value.slice(0, splitter) + "-" + value.slice(splitter)
    }

    if (!value.match(/^(\d{6})-(\d{4})|(\d{8})-(\d{4})$/)) { return null }

    // Clean value
    value = value.replace('-', '')
    if (value.length === 12) {
        value = value.substring(2)
    }

    const year = parseInt(value.substr(0, 2));
    const month = parseInt(value.substr(2, 2)) - 1;
    const day = parseInt(value.substr(4, 2));

    const d = new Date()
    d.setYear(year)
    d.setMonth(month)
    d.setDate(day)

    // Declare variables
    let sum = 0,
        numdigits = value.length,
        parity = numdigits % 2,

        digit

    // Check valid date
    if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) return null
    if (+d.getFullYear().toString().substr(2, 4) !== year || d.getMonth() !== month || d.getDate() !== day) {
        return null
    }

    // Check luhn algorithm
    for (let i = 0; i < numdigits; i++) {
        digit = parseInt(value.charAt(i), 10)
        if (i % 2 === parity) { digit *= 2 }
        if (digit > 9) { digit -= 9 }
        sum += digit
    }

    return (sum % 10) === 0
}

const SwedishSamordningValidator = (value) => {
    if (!value) { return null }

    if (value.indexOf('-') === -1) {
        let splitter = value.length === 10 ? 6 : 8
        value = value.slice(0, splitter) + "-" + value.slice(splitter)
    }

    if (!value.match(/^(\d{6})-(\d{4})|(\d{8})-(\d{4})$/)) { return null }

    // Clean value
    value = value.replace('-', '')
    if (value.length === 12) {
        value = value.substring(2)
    }

    if (parseInt(value.substr(4, 2)) < 61)
        return null;

    // Declare variables
    var sum = 0,
        numdigits = value.length,
        parity = numdigits % 2,
        i,
        digit

    // Check luhn algorithm
    for (i = 0; i < numdigits; i++) {
        digit = parseInt(value.charAt(i), 10)
        if (i % 2 === parity) { digit *= 2 }
        if (digit > 9) { digit -= 9 }
        sum += digit
    }

    return (sum % 10) === 0

}

const SwedishReserveValidator = (value) => {
    if (value.length < 10)
        return null

    return !SwedishSsnValidator(value) && !SwedishSamordningValidator(value)
}

export {
    SwedishSsnValidator,
    SwedishSamordningValidator,
    SwedishReserveValidator
}
